import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
// import { InPageNavigationRoundtables } from "../components/inpage-navigation"
import roundtablecompanies from "../images/logo/roundtable-companies.png"
import { InPageNavigationRoundtables } from "../components/inpage-navigation"

import exail from "../images/logo/exail.png"
import easics from "../images/logo/easics.png"
import barco from "../images/logo/barco.png"
import robovision from "../images/logo/robovision.png"
import multi from "../images/logo/multi.png"
import sweco from "../images/logo/sweco.png"
import daikin from "../images/logo/daikin.png"
import arcelor from "../images/logo/arcelor.png"
import melexis from "../images/logo/melexis.png"
import sirus from "../images/logo/sirus.png"

const Roundtables = () => (
    <Layout>
        <Seo title="FEARS Roundtables" />
        <InPageNavigationRoundtables />
        <div className="fluid-container">
            <div className="row">
                <article id="portal-column-content" className="cell col-sm-9 mbottom-default">
                    <h1>Roundtables</h1>

                    <h2 id="roundtable">Industry Roundtables</h2>
                    {/* <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <button className="btn btn-default" href="https://evan.ugent.be/r/fears2023/" >
                                <a href="https://evan.ugent.be/r/fears2023/" activeClassName="active">Register for FEARS (and select the roundtables)</a>
                            </button>
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col-sm-4">
                            <h3>Target group</h3>
                            <p>All PhD students or other researchers.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Participant limit</h3>
                            <p>5 x 8 places.</p>
                        </div>
                        <div className="col-sm-4">
                            <h3>Date</h3>
                            <p>Thursday October 26th (FEARS), 14:00-14:45</p>
                        </div>
                    </div>
                    <div className="row pbottom-default">
                        <div className="col-sm-4">
                            {/* <h3>Target group</h3> */}
                            <p>All PhD students or other researchers.</p>
                        </div>
                        <div className="col-sm-4">
                            {/* <h3>Participant limit</h3> */}
                            <p>5 x 8 places.</p>
                        </div>
                        <div className="col-sm-4">
                            {/* <h3>Date</h3> */}
                            <p>Thursday October 26th (FEARS), 15:45-16:30</p>
                        </div>
                    </div>

                    <div className="row pbottom-default">
                        <div className="col-sm-12">
                            <h3>Session content</h3>
                            <p>
                                The Industry Roundtables, introduced for the first time at FEARS, promise an enriching experience tailored to PhD researchers eager to connect with industry. A total of 10 Roundtables will be organised covering the spectrum of engineering and architecture disciplines present at our faculty. Each table will evolve around a hot topic in engineering or architecture, represented by at least one leading company in this field. The participation limit is set to 8 researchers per table. This to guarantee an interactive and focused setting for your discussion.
                            </p>
                            <p>
                                This format allows you to closely engage with industry specialists, dive deeper into topics mirroring your academic pursuits and share first hand insights on the state of the art in your field! This hands-on workshop not only empowers researchers with a clearer understanding of trends in industry but also provides participating companies with a platform to showcase their commitment to innovation and benchmark their advancements against academia. Attendees are encouraged to send in questions during registration, ensuring an interactive and well-rounded dialogue.
                            </p>
                        </div>
                        {/* <div className="col-sm-8">
                            <img width={840} src={roundtablecompanies} alt="Roundtable companies at FEARS2023"></img>
                        </div> */}
                    </div>
                    <div className="col-sm-12">
                        <div className="row pbottom-default">
                            <h2>Roundtable topics</h2>
                            <p>Participants of the <Link to="/workshops#questions">workshop on Q&amp;A</Link> will get priority selection of their prefered roundtable topic.</p>

                            <div className="row pbottom-default" id="exail">
                                <div className="col-sm-2">
                                    <h3>TOPIC 1</h3>
                                    <p>Exail</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>The use of AI in de-mining applications: data management, algorithms, explainability, acceptance</h3>
                                    <p>The merger of ECA Group and iXblue makes Exail the top industrial player in high-tech solutions for the civil and defense sectors. The Belgian division - Exail Robotics Belgium - develops underwater drones that detect, identify and destroy mines. Thanks to our innovative concept, which our customers such as the Belgian and Dutch navies will use, we can significantly reduce the risks involved in demining. These technologies are safer and faster than traditional ways.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={exail} alt="Exail"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="easics">
                                <div className="col-sm-2">
                                    <h3>TOPIC 2</h3>
                                    <p>Easics</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>AI at the extreme edge: near-sensor and on-chip for smart cameras in automotive, IoT, industrial, medical, extended reality </h3>
                                    <p>easics is a semiconductor chip design and supply company. easics targets ASIC/SoC and FPGA/SoC-based designs. Besides doing custom designs, easics licenses semiconductor intellectual property "nearbAI" for doing near-sensor AI. Verticals include automotive, healthcare, consumer / IoT, industry and space. easics was founded in 1991. The easics team consists of 40+ people, based in Leuven. easics is continuously hiring masters and PhDs in electrical engineering, computer engineering, and related degrees.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={easics} alt="Easics"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="barco">
                                <div className="col-sm-2">
                                    <h3>TOPIC 3</h3>
                                    <p>Barco</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>The process/hurdles/challenges on bringing research from academia through industry to the market.</h3>
                                    <p>Barco is a global company with headquarters in Kortrijk (Belgium). Our visualization and collaboration technology helps professionals accelerate innovation in the healthcare, enterprise and entertainment markets. We count over 3,000 visioneers, whose passion for technology is captured in over 500 unique patents. At Barco, we believe truly great engineering starts with a clear vision. A vision of a better, smarter, healthier world.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={barco} alt="Barco"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="robovision">
                                <div className="col-sm-2">
                                    <h3>TOPIC 4</h3>
                                    <p>Robovision</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>How to facilitate the academic work on AI to be implemented into industry?
                                    </h3>
                                    <p>In modern AI, computer vision is the most mature field that enables the automation of human sight.Robovision developed an easy-to-use platform to solve your vision-related use cases, no matter how challenging. Together with a team of more than 100 people, we strongly believe in collaborative intelligence between humans and machines. Thanks to the Robovision AI (RVAI) platform, operators with no technical background can build their own AI solutions to automate quality control, picking up objects and do other automated actions. They can easily create AI models and retrain them if a new defect occurs for instance.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={robovision} alt="Robovision"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="multi">

                                <div className="col-sm-2">
                                    <h3>TOPIC 5</h3>
                                    <p>MULTI.engineering</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Industrialization of the production processes and supply chain of offshore floating renewable energy installations & their repair and maintenance solutions.
                                    </h3>
                                    <p>MULTI.engineering is a design- and engineering company focusing on the Maritime & Offshore industry. Our clients are typically shipyards, ship owners or offshore contractors whom we support during the concept development, basic design and detail engineering for newbuilding projects, conversions of existing assets and the technical operations of their fleets. We are a group of 250 engineers, experts and technical advisors and we do serve clients in the EU and USA. Over the past few years our interest and involvement in innovative and disruptive developments for the shipping and offshore renewable market has grown. Some developments are: unmanned offshore operations, sustainable ship propulsion, alternative materials, solutions for the growing floating waste problem...</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={multi} alt="MULTI.engineering"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="sweco">
                                <div className="col-sm-2">
                                    <h3>TOPIC 6</h3>
                                    <p>Sweco</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Sweco's topic
                                    </h3>
                                    <p>Sweco is a European engineering consultancy company, active in the fields of consulting engineering, environmental technology and architecture. Sweco is one of Europe's leading architecture and engineering consultancy company, established in 14 countries.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={sweco} alt="Sweco"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="daikin">
                                <div className="col-sm-2">
                                    <h3>TOPIC 7</h3>
                                    <p>Daikin</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>What are possible renewable energy solutions in densely populated areas?
                                    </h3>
                                    <p>Daikin Europe is a leading provider of heating, cooling, ventilation, air purification and refrigeration technology for residential, commercial and industrial purposes. We offer a wide range of products, solutions and services to create indoor environments that are beneficial to people's health and wellbeing. Our advanced technology enables our customers to reduce their environmental impact and contribute to a more sustainable society. The EMEA Development Centre is where Daikin turns tangible research into the climate changing technologies of tomorrow.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={daikin} alt="Daikin"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="arcelor">
                                <div className="col-sm-2">
                                    <h3>TOPIC 8</h3>
                                    <p>ArcelorMittal</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>How a modelling department can make the difference in a steel company.
                                    </h3>
                                    <p>ArcelorMittal Belgium is part of the ArcelorMittal group, one of the world's leading steel and mining companies. Our Belgian cluster with sites in Ghent, Liège, Geel and Genk has all necessary facilities on site to convert the raw materials supplied in a sustainable and innovative way into steel products with high added value. Cars, wind turbines, design houses... everywhere you look, you come across steel from ArcelorMittal Belgium. ArcelorMittal Belgium is among the world's leaders in energy and CO2 efficiency and, as a high-tech company, is a pioneer in CO2 reduction. We have committed to reduce our CO2 emissions by 35% by 2030 compared to 2018, and to be carbon neutral by 2050. The concrete implementation of our CO2 roadmap towards carbon neutrality by 2050 is currently being deployed.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={arcelor} alt="ArcelorMittal"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="melexis">
                                <div className="col-sm-2">
                                    <h3>TOPIC 9</h3>
                                    <p>Melexis</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Sensors and Systems for digital health</h3>
                                    <p>Melexis is a global supplier of micro-electronic semiconductor solutions and stands for engineering that enables the best imaginable future - a future which is safe, clean, comfortable and healthy. That's what we call inspired engineering. Our technology makes cars and other products smarter, safer and greener. Our sensors capture data from the analog world and comprehend these data digitally. Our drivers make sure customers can bring their products to life.</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={300} src={melexis} alt="Melexis"></img>
                                </div>
                            </div>
                            <div className="row pbottom-default" id="sirus">
                                <div className="col-sm-2">
                                    <h3>TOPIC 10</h3>
                                    <p>Sirus</p>
                                </div>
                                <div className="col-sm-7">
                                    <h3>Data spaces, sharing of data between different organisations in order to solve pressing challenges our society is currently facing.</h3>
                                    <p>Sirus, founded in 2011, is a Belgian software company with a dynamic team of about 50 employees and focuses on development, architecture and consultancy. Sirus' mission is to guide customers through the rapidly changing IT landscape. Our expertise, extensive network, and customer-oriented approach are central to all our projects. Sirus delivers quality solutions specialized in .NET, M365, power platform, Azure, IoT and data solutions for smart cities and smart businesses. This expertise proves itself in our collaboration with Microsoft, as Microsoft Solutions Partner for Data & AI and for Digital and App innovation</p>
                                </div>
                                <div className="col-sm-3">
                                    <img width={200} src={sirus} alt="Sirus"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            </div>
        </div>
    </Layout>
)

export default Roundtables
